const ProgramInfo: React.FC<{ label: string; value: string; icon: string }> = ({
  label,
  value,
  icon,
}) => {
  return (
    <div>
      <p className="mb-3 text-sm uppercase font-medium">
        <i className={`${icon} text-red-500 text-base`}></i> {label}
      </p>
      <h5 className="text-base text-gray-700 font-medium">{value}</h5>
    </div>
  );
};

export default ProgramInfo;
