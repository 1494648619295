import React from "react";

interface CardFooterProps {
  date: string;
  tasks: number;
  comments: number;
  progress: number;
}

const CardFooter: React.FC<CardFooterProps> = ({ date, tasks, comments, progress }) => {
  return (
    <div className="border-t p-5 border-gray-300 dark:border-gray-700">
      <div className="grid lg:grid-cols-2 gap-4">
        <div className="flex items-center justify-between gap-2">
          <a href="#" className="text-sm">
            <i className="mgc_calendar_line text-lg me-2"></i>
            <span className="align-text-bottom">{date}</span>
          </a>

          <a href="#" className="text-sm">
            <i className="mgc_align_justify_line text-lg me-2"></i>
            <span className="align-text-bottom">{tasks}</span>
          </a>

          <a href="#" className="text-sm">
            <i className="mgc_comment_line text-lg me-2"></i>
            <span className="align-text-bottom">{comments}</span>
          </a>
        </div>
        <div className="flex items-center gap-2">
          <div className="w-full bg-gray-200 rounded-full h-1.5 dark:bg-gray-700">
            <div
              className={`bg-${progress >= 50 ? "success" : "warning"} h-1.5 rounded-full`}
              style={{ width: `${progress}%` }}
            ></div>
          </div>
          <span>{progress}%</span>
        </div>
      </div>
    </div>
  );
};

export default CardFooter;
