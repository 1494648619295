import React from "react";
import PageTitle from "../../components/PageTitle";
import GeneralContent from "../../components/General/GeneralContent";

const General: React.FC = () => {
  return (
    <>
      <PageTitle pagetitle={"General"} subtitle={"Extra Pages"} />
      <div className="w-full">
        <GeneralContent />
      </div>
    </>
  );
};

export default General;
