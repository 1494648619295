import Tag from "../UIElements/Tags";

const Tags: React.FC = () => {
  return (
    <div className="mb-6">
      <h6 className="font-medium my-3 text-gray-800">Tags</h6>
      <div className="uppercase flex gap-4">
        <Tag name="Html" />
        <Tag name="Css" />
        <Tag name="Tailwind" />
        <Tag name="JavaScript" />
      </div>
    </div>
  );
};

export default Tags;
