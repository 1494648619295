import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Login from "./pages/auth/Login";
import Dashboard from "./pages/Dashboard";
import Functions from "./pages/settings/Functions";
import Role from "./pages/admin/Role";
import Admin from "./pages/admin/Admin";
import { useAuth } from "./context/AuthContext";
import Private from "./context/Private";
import General from "./pages/settings/General";
import Candidates from "./pages/candidates/Candidates";
import AddCandidate from "./pages/candidates/AddCandidate";
import Layout from "./Layout";
import Institution from "./pages/institution/Institution";
import Programs from "./pages/programs/Programs";
import AddProgram from "./pages/programs/AddProgram";
import Program from "./pages/programs/Program";
import CrubList from "./pages/crubList/CrubList";

const AppRoutes: React.FC = () => {
  const { isAuthenticated } = useAuth();

  return (
    <Routes>
      <Route
        path="/login"
        element={isAuthenticated ? <Navigate to="/" /> : <Login />}
      />
      <Route
        path="/"
        element={
          <Private
            route
            element={
              <Layout>
                <Dashboard />
              </Layout>
            }
          />
        }
      />
      <Route
        path="/institution"
        element={
          <Private
            route
            element={
              <Layout>
                <Institution />
              </Layout>
            }
          />
        }
      />
      <Route
        path="/candidates"
        element={
          <Private
            route
            element={
              <Layout>
                <Candidates />
              </Layout>
            }
          />
        }
      />
      <Route
        path="/candidates/add"
        element={
          <Private
            route
            element={
              <Layout>
                <AddCandidate />
              </Layout>
            }
          />
        }
      />
      <Route
        path="/programs"
        element={
          <Private
            route
            element={
              <Layout>
                <Programs />
              </Layout>
            }
          />
        }
      />
      <Route
        path="/programs/add"
        element={
          <Private
            route
            element={
              <Layout>
                <AddProgram />
              </Layout>
            }
          />
        }
      />

      <Route
        path="/programs/:id"
        element={
          <Private
            route
            element={
              <Layout>
                <Program />
              </Layout>
            }
          />
        }
      />

      <Route
        path="/crub-list"
        element={
          <Private
            route
            element={
              <Layout>
                <CrubList />
              </Layout>
            }
          />
        }
      />

      <Route
        path="/role"
        element={
          <Private
            permissions={["new"]}
            route
            element={
              <Layout>
                <Role />
              </Layout>
            }
          />
        }
      />
      <Route
        path="/admins"
        element={
          <Private
            permissions={["new"]}
            route
            element={
              <Layout>
                <Admin />
              </Layout>
            }
          />
        }
      />
      <Route
        path="/general"
        element={
          <Private
            route
            element={
              <Layout>
                <General />
              </Layout>
            }
          />
        }
      />
      <Route
        path="/functions"
        element={
          <Private
            route
            element={
              <Layout>
                <Functions />
              </Layout>
            }
          />
        }
      />
    </Routes>
  );
};

export default AppRoutes;
