import React from "react";
import { useQuery } from "@apollo/client";
import { GET_CATEGORIES } from "../../graphql/queries/category";

interface CategoryInputProps {
  category: string;
  setCategory: (category: string) => void;
}

const CategoryInput: React.FC<CategoryInputProps> = ({ category, setCategory }) => {
  // Fetch categories data using Apollo Client
  const { data, loading, error } = useQuery(GET_CATEGORIES);

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setCategory(e.target.value);
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading categories</p>;

  return (
    <div className="mb-3">
      <label
        htmlFor="categorySelect"
        className="text-gray-800 text-sm font-medium inline-block mb-2"
      >
        Select Category
      </label>
      <select
        id="categorySelect"
        className="form-select"
        value={category}
        onChange={handleChange}
        required
      >
        <option value="" disabled>
          Select a Category
        </option>
        {data.Categories.map((category: any) => (
          <option key={category.id} value={category.id}>
            {category.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default CategoryInput;
