import AssignedUser from "./AssignedUser";

const AssignedTo: React.FC = () => {
  return (
    <div className="mt-6">
      <h6 className="text-sm text-gray-800 font-medium mb-3">Assign To</h6>
      <div className="flex flex-wrap -space-x-2">
        <AssignedUser imgSrc="assets/images/users/avatar-2.jpg" />
        <AssignedUser imgSrc="assets/images/users/avatar-3.jpg" />
        <AssignedUser imgSrc="assets/images/users/avatar-9.jpg" />
        <AssignedUser imgSrc="assets/images/users/avatar-10.jpg" />
      </div>
    </div>
  );
};

export default AssignedTo;
