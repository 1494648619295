import { gql } from "@apollo/client";

export const GET_INSTITUTIONS = gql`
  query GetInstitution {
    institutions {
      id
      name
    }
  }
`;
