import React from "react";

interface CardProps {
  count: number;
  label: string;
  bgColor: string;
  iconClass: string;
}

const Card: React.FC<CardProps> = ({ count, label, bgColor, iconClass }) => {
  return (
    <div className="card">
      <div className="p-5">
        <div className="flex justify-between">
          <div className={`w-20 h-20 rounded-full inline-flex items-center justify-center ${bgColor}`}>
            <i className={iconClass}></i>
          </div>
          <div className="text-right">
            <h3 className="text-gray-700 mt-1 text-2xl font-bold mb-5 dark:text-gray-300">{count}</h3>
            <p className="text-gray-500 mb-1 truncate dark:text-gray-400">{label}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
