import React from "react";
import { useQuery } from "@apollo/client";
import { GET_INSTITUTIONS } from "../../graphql/queries/institution";

interface InstitutionInputProps {
  institution: string;
  setInstitution: (institution: string) => void;
}

const InstitutionInput: React.FC<InstitutionInputProps> = ({
  institution,
  setInstitution,
}) => {
  // Fetch institutions data using Apollo Client
  const { data, loading, error } = useQuery(GET_INSTITUTIONS);

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setInstitution(e.target.value);
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading institutions</p>;

  return (
    <div className="mb-3">
      <label
        htmlFor="institutionSelect"
        className="text-gray-800 text-sm font-medium inline-block mb-2"
      >
        Select Institution
      </label>
      <select
        id="institutionSelect"
        className="form-select"
        value={institution}
        onChange={handleChange}
        required
      >
        <option value="" disabled>
          Select a Institution
        </option>
        {data.institutions.map((institution: any) => (
          <option key={institution.id} value={institution.id}>
            {institution.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default InstitutionInput;
