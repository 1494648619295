import React, { ReactNode } from "react";
import Sidebar from "./components/sidebar/Sidebar";
import Topbar from "./components/topbar/Topbar";

// Define the prop types for Layout
interface LayoutProps {
  children: ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => (
  <div className="flex wrapper">
    <Sidebar />
    <div className="page-content">
      <Topbar />
      <main className="flex-grow p-6">{children}</main>
    </div>
  </div>
);

export default Layout;
