import { gql } from "@apollo/client";

export const GET_GROUPS = gql`
  query GetGroup {
    groups {
      id
      name
    }
  }
`;
