import React from "react";
import PageTitle from "../components/PageTitle";

const Dashboard: React.FC = () => {
  return (
    <>
      <PageTitle pagetitle={"Dashboard"} subtitle={"Menu"} />
      <div className="w-11/12"></div>
    </>
  );
};

export default Dashboard;
