import React from "react";

const AddImage: React.FC = () => {
  return (
    <div className="card p-6">
      <div className="flex justify-between items-center mb-4">
        <h4 className="card-title">Add Avatar Images</h4>
        <div className="inline-flex items-center justify-center rounded-lg bg-slate-100 dark:bg-slate-700 w-9 h-9">
          <i className="mgc_add_line"></i>
        </div>
      </div>

      <form
        action="#"
        className="dropzone flex items-center justify-center text-gray-700 dark:text-gray-300 h-52"
      >
        <div className="dz-message text-center needsclick w-full">
          <i className="mgc_pic_2_line text-8xl"></i>
        </div>
      </form>
    </div>
  );
};

export default AddImage;
