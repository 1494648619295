import React from "react";
import Sections from "./Sections";
import Mark from "./Mark";

const SectionGrid: React.FC = () => {
    return (
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
            <Sections />
            <Mark />
        </div>
    )
}

export default SectionGrid;