import React from "react";
import PageTitle from "../../components/PageTitle";
import Candidate from "../../components/programs/Candidate";
import Tags from "../../components/programs/Tags";
import ProgramDetails from "../../components/programs/ProgramDetails";
import AssignedTo from "../../components/programs/AssignedTo";
import AttachedFiles from "../../components/programs/AttachedFiles";

const Program: React.FC = () => {
  return (
    <>
      <PageTitle pagetitle={"Program"} subtitle={"Programs"} />
      <div className="grid lg:grid-cols-3 gap-6">
        <div className="lg:col-span-2">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">About Project</h4>
            </div>

            <div className="p-6">
              <p className="text-gray-500 mb-4 text-sm">
                To an English person, it will seem like simplified English, as a
                skeptical Cambridge friend of mine told me what Occidental is.
                The European languages are members of the same family. Their
                separate existence is a myth.
              </p>
              <p className="text-gray-500 mb-4 text-sm">
                Everyone realizes why a new common language would be desirable:
                one could refuse to pay expensive translators. To achieve this,
                it would be necessary to have uniform grammar, pronunciation and
                more common words.
              </p>

              <ul className="ps-9 mb-9 list-disc">
                <li>Quis autem vel eum iure</li>
                <li>Ut enim ad minima veniam</li>
                <li>Et harum quidem rerum</li>
                <li>Nam libero cum soluta</li>
              </ul>

              <Tags />
              <ProgramDetails />
              <AssignedTo />
              <AttachedFiles />
            </div>
          </div>
        </div>
        <div className="col-span-1">
          <div className="card">
            <div className="card-header">
              <h6 className="card-title">Project Activities</h6>
            </div>
            <div className="table overflow-hidden w-full">
              <div className="divide-y divide-gray-300 overflow-auto w-full max-w-full">
                <Candidate
                  imgSrc="assets/images/users/avatar-1.jpg"
                  name="James Walton"
                  email="jameswalton@gmail.com"
                  task="Wire Frame"
                  status="Working"
                  statusColor="primary"
                />
                <Candidate
                  imgSrc="assets/images/users/avatar-2.jpg"
                  name="Jerry Geiger"
                  email="jerrygeiger@gmail.com"
                  task="Figma Design"
                  status="Completed"
                  statusColor="success"
                />
                {/* Add more Activity components here */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Program;
