import { gql } from "@apollo/client";

// Create Role Mutation
export const CREATE_ROLE = gql`
  mutation CreateRole(
    $name: String!
    $institution: Boolean!
    $category: Boolean!
    $section: Boolean!
    $group: Boolean!
    $permissions: [ID!]!
  ) {
    createRole(
      input: {
        name: $name
        institution: $institution
        category: $category
        section: $section
        group: $group
        permissions: $permissions
      }
    ) {
      id
      name
      permissions {
        id
        name
      }
    }
  }
`;

// Update Role Mutation
export const UPDATE_ROLE = gql`
  mutation UpdateRole(
    $id: ID!
    $name: String
    $institution: Boolean
    $category: Boolean
    $section: Boolean
    $group: Boolean
    $permissions: [ID!]
  ) {
    updateRole(
      id: $id
      input: {
        name: $name
        institution: $institution
        category: $category
        section: $section
        group: $group
        permissions: $permissions
      }
    ) {
      id
      name
    }
  }
`;

export const DELETE_ROLE = gql`
  mutation DeleteRole($id: ID!) {
    deleteRole(id: $id)
  }
`;
