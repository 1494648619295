import React, { useEffect } from "react";

const BackToTopButton: React.FC = () => {
  useEffect(() => {
    const backToTopButton = document.querySelector(
      '[data-toggle="back-to-top"]'
    ) as HTMLElement;

    const handleScroll = () => {
      if (window.pageYOffset > 72) {
        backToTopButton.classList.add("flex");
        backToTopButton.classList.remove("hidden");
      } else {
        backToTopButton.classList.remove("flex");
        backToTopButton.classList.add("hidden");
      }
    };

    const handleClick = (e: Event) => {
      e.preventDefault();
      window.scrollTo({ top: 0, behavior: "smooth" });
    };

    window.addEventListener("scroll", handleScroll);
    backToTopButton.addEventListener("click", handleClick);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      backToTopButton.removeEventListener("click", handleClick);
    };
  }, []);

  return (
    <button
      data-toggle="back-to-top"
      className="fixed hidden h-10 w-10 items-center justify-center rounded-full z-10 bottom-3 end-3 p-2.5 bg-primary cursor-pointer shadow-lg text-white"
    >
      <i className="mgc_arrow_up_line text-lg"></i>
    </button>
  );
};

export default BackToTopButton;
