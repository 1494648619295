import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AppRoutes from "./AppRoutes";  
import BackToTopButton from "./components/BackToTopButton";  
import "./App.css";
import "./Icon.css";

const App: React.FC = () => {
  return (
    <>
      <Router>
        <AppRoutes />
      </Router>

      {/* Back to Top Button */}
      <BackToTopButton />  
    </>
  );
};

export default App;
