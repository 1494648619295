import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "./AuthContext";

interface PrivateProps {
  roles?: string[];
  permissions?: string[];
  element: React.ReactElement;
  route?: boolean;
}

const Private: React.FC<PrivateProps> = ({
  roles = [],
  permissions = [],
  element,
  route,
}) => {
  const { isAuthenticated, user } = useAuth();

  if (!user && isAuthenticated) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    return <Navigate to={`/login?redirect=${location.pathname}`} />;
  }

  if (user.role.name == "administration") {
    return element;
  }

  if (roles.length && !roles.includes(user.role.name)) {
    if (route) {
      return <Navigate to="/" />;
    } else {
      return null;
    }
  }

  const hasPermissions = (
    userPermissions: any[],
    requiredPermissions: string[]
  ) => {
    return requiredPermissions.every((p) =>
      userPermissions.some((perm: any) => perm.name === p && perm.isActive)
    );
  };

  if (
    permissions.length &&
    !hasPermissions(user.role.permissions, permissions)
  ) {
    if (route) {
      return <Navigate to="/" />;
    } else {
      return null;
    }
  }

  return element;
};

export default Private;
