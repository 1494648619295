import React from "react";
import { useQuery } from "@apollo/client";
import { GET_ROLES } from "../../graphql/queries/role";

interface RoleInputProps {
  role: string;
  setRole: (role: string) => void;
  group: (value: boolean) => void;
  institution: (value: boolean) => void;
  category: (value: boolean) => void;
  section: (value: boolean) => void;
}

const RoleInput: React.FC<RoleInputProps> = ({
  role,
  setRole,
  group,
  institution,
  section,
  category,
}) => {
  // Fetch roles data using Apollo Client
  const { data, loading, error } = useQuery(GET_ROLES);

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedRoleId = e.target.value;
    setRole(selectedRoleId);

    // Find the selected role from the data
    const selectedRole = data?.roles.find((r: any) => r.id === selectedRoleId);
    group(selectedRole.group);
    institution(selectedRole.institution);
    section(selectedRole.section);
    category(selectedRole.category);
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading roles</p>;

  return (
    <div className="mb-3">
      <label
        htmlFor="roleSelect"
        className="text-gray-800 text-sm font-medium inline-block mb-2"
      >
        Select Role
      </label>
      <select
        id="roleSelect"
        className="form-select"
        value={role}
        onChange={handleChange}
        required
      >
        <option value="" disabled>
          Select a Role
        </option>
        
        {data.roles.map((role: any) => (
          <option
            key={role.id}
            value={role.id}
          >
            {role.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default RoleInput;
