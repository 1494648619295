import React from "react";
import Card from "../../components/programs/Card";
import PageTitle from "../../components/PageTitle";
import { Link } from "react-router-dom";

const Programs: React.FC = () => {
  return (
    <>
      <PageTitle pagetitle={"Programs"} subtitle={"Programs"} />
      <div className="grid grid-cols-6">
        <div className="relative col-span-5 mr-3">
          <div className="pointer-events-none absolute top-3.5 start-4 text-gray-900 text-opacity-40 dark:text-gray-200">
            <i className="mgc_search_line text-xl"></i>
          </div>
          <input
            type="search"
            className="h-12 w-full border-1 rounded-lg bg-transparent ps-11 pe-4 text-gray-900 placeholder-gray-500 dark:placeholder-gray-300 dark:text-gray-200 focus:ring-0 sm:text-sm"
            placeholder="Search..."
          />
        </div>

        <Link
          to="/programs/add"
          className="btn bg-danger/20 text-sm font-medium text-danger hover:text-white hover:bg-danger"
        >
          <i className="mgc_add_circle_line me-3"></i> Add Program
        </Link>
      </div>
      <div className="flex flex-auto flex-col mt-6">
        <div className="grid md:grid-cols-2 xl:grid-cols-3 gap-6">
          <Card
            title="Web Design"
            status="Completed"
            statusType="success"
            taskTitle="Landing page Design"
            taskDescription="If several languages coalesce, the grammar of the resulting language is more regular."
            avatars={[
              "assets/images/users/avatar-2.jpg",
              "assets/images/users/avatar-3.jpg",
            ]}
            date="15 Dec"
            comments={224}
            tasks={56}
            progress={66}
          />
          <Card
            title="Android"
            status="Pending"
            statusType="warning"
            taskTitle="App Design and Develop"
            taskDescription="To achieve this, it would be necessary to have uniform grammar and more common words."
            avatars={[
              "assets/images/users/avatar-4.jpg",
              "assets/images/users/avatar-5.jpg",
              "assets/images/users/avatar-6.jpg",
              "assets/images/users/avatar-7.jpg",
            ]}
            date="28 Nov"
            comments={196}
            tasks={62}
            progress={33}
          />
          <Card
            title="Web Design"
            status="Frontend Completed"
            statusType="success"
            taskTitle="New Admin Design"
            taskDescription="If several languages coalesce, the grammar of the resulting language is more regular."
            avatars={[
              "assets/images/users/avatar-9.jpg",
              "assets/images/users/avatar-10.jpg",
              "assets/images/users/avatar-1.jpg",
            ]}
            date="19 Nov"
            comments={102}
            tasks={69}
            progress={50}
          />

          <Card
            title="Web Design"
            status="Completed"
            statusType="success"
            taskTitle="Landing page Design"
            taskDescription="If several languages coalesce, the grammar of the resulting language is more regular."
            avatars={[
              "assets/images/users/avatar-2.jpg",
              "assets/images/users/avatar-3.jpg",
            ]}
            date="15 Dec"
            comments={224}
            tasks={56}
            progress={66}
          />
          <Card
            title="Android"
            status="Pending"
            statusType="warning"
            taskTitle="App Design and Develop"
            taskDescription="To achieve this, it would be necessary to have uniform grammar and more common words."
            avatars={[
              "assets/images/users/avatar-4.jpg",
              "assets/images/users/avatar-5.jpg",
              "assets/images/users/avatar-6.jpg",
              "assets/images/users/avatar-7.jpg",
            ]}
            date="28 Nov"
            comments={196}
            tasks={62}
            progress={33}
          />
          <Card
            title="Web Design"
            status="Frontend Completed"
            statusType="success"
            taskTitle="New Admin Design"
            taskDescription="If several languages coalesce, the grammar of the resulting language is more regular."
            avatars={[
              "assets/images/users/avatar-9.jpg",
              "assets/images/users/avatar-10.jpg",
              "assets/images/users/avatar-1.jpg",
            ]}
            date="19 Nov"
            comments={102}
            tasks={69}
            progress={50}
          />
        </div>

        <div className="text-center mt-6">
          <button
            type="button"
            className="btn bg-transparent border-gray-300 dark:border-gray-700"
          >
            <i className="mgc_loading_4_line me-2 animate-spin"></i>
            <span>Load More</span>
          </button>
        </div>
      </div>
    </>
  );
};

export default Programs;
