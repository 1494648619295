import React, { useState } from "react";

const AddForm: React.FC = () => {
  const [projectName, setProjectName] = useState("");
  const [projectDescription, setProjectDescription] = useState("");
  const [status, setStatus] = useState("private");
  const [startDate, setStartDate] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [label, setLabel] = useState("");
  const [budget, setBudget] = useState("");

  return (
    <div className="lg:col-span-3 space-y-6">
      <div className="card p-6">
        <div className="flex justify-between items-center mb-4">
          <p className="card-title">General Product Data</p>
          <div className="inline-flex items-center justify-center rounded-lg bg-slate-100 dark:bg-slate-700 w-9 h-9">
            <i className="mgc_transfer_line"></i>
          </div>
        </div>

        <div className="flex flex-col gap-3">
          <div>
            <label htmlFor="project-name" className="mb-2 block">
              Project Name
            </label>
            <input
              type="text"
              id="project-name"
              className="form-input"
              placeholder="Enter Title"
              value={projectName}
              onChange={(e) => setProjectName(e.target.value)}
            />
          </div>

          <div>
            <label htmlFor="project-description" className="mb-2 block">
              Project Description <span className="text-red-500">*</span>
            </label>
            <textarea
              id="project-description"
              className="form-input"
              rows={8}
              value={projectDescription}
              onChange={(e) => setProjectDescription(e.target.value)}
            ></textarea>
          </div>

          <div>
            <label htmlFor="product-status" className="mb-2 block">
              Status <span className="text-red-500">*</span>
            </label>
            <div className="flex gap-x-6">
              <div className="flex">
                <input
                  type="radio"
                  name="radio-group"
                  className="form-radio"
                  id="private"
                  checked={status === "private"}
                  onChange={() => setStatus("private")}
                />
                <label
                  htmlFor="private"
                  className="text-sm text-gray-500 ms-2 dark:text-gray-400"
                >
                  Private
                </label>
              </div>

              <div className="flex">
                <input
                  type="radio"
                  name="radio-group"
                  className="form-radio"
                  id="team"
                  checked={status === "team"}
                  onChange={() => setStatus("team")}
                />
                <label
                  htmlFor="team"
                  className="text-sm text-gray-500 ms-2 dark:text-gray-400"
                >
                  Team
                </label>
              </div>

              <div className="flex">
                <input
                  type="radio"
                  name="radio-group"
                  className="form-radio"
                  id="public"
                  checked={status === "public"}
                  onChange={() => setStatus("public")}
                />
                <label
                  htmlFor="public"
                  className="text-sm text-gray-500 ms-2 dark:text-gray-400"
                >
                  Public
                </label>
              </div>
            </div>
          </div>

          <div className="grid md:grid-cols-2 gap-3">
            <div>
              <label htmlFor="start-date" className="mb-2 block">
                Start Date
              </label>
              <input
                type="date"
                id="start-date"
                className="form-input"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </div>

            <div>
              <label htmlFor="due-date" className="mb-2 block">
                Due Date
              </label>
              <input
                type="date"
                id="due-date"
                className="form-input"
                value={dueDate}
                onChange={(e) => setDueDate(e.target.value)}
              />
            </div>
          </div>

          <div>
            <label htmlFor="select-label" className="mb-2 block">
              Label
            </label>
            <select
              id="select-label"
              className="form-select"
              value={label}
              onChange={(e) => setLabel(e.target.value)}
            >
              <option selected>Open this select menu</option>
              <option>Medium</option>
              <option>High</option>
              <option>Low</option>
            </select>
          </div>

          <div>
            <label htmlFor="budget" className="mb-2 block">
              Budget
            </label>
            <input
              type="text"
              id="budget"
              className="form-input"
              placeholder="Enter Project Budget"
              value={budget}
              onChange={(e) => setBudget(e.target.value)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddForm;
