import { gql } from "@apollo/client";

export const LOGIN_MUTATION = gql`
  mutation Login($username: String!, $password: String!) {
    login(input: { username: $username, password: $password }) {
      token
      user {
        id
        username
        role {
          name
          permissions {
            name
            isActive
          }
        }
        section {
          id
          sectionName
        }
      }
    }
  }
`;

export const LOGOUT_MUTATION = gql`
  mutation Logout {
    logout
  }
`;

export const CHANGE_PASSWORD_MUTATION = gql`
  mutation ChangePassword($id: ID!, $password: String!, $newPassword: String!) {
    changePassword(
      id: $id
      input: { password: $password, newPassword: $newPassword }
    ) {
      id
    }
  }
`;
