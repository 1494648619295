import React, { useState } from "react";
import InstitutionCard from "./InstitutionCard";
import Modal from "../UIElements/Model";

interface GroupCardProps {
  title: string;
  data: {
    imgSrc: string;
    shortName: string;
    fullName: string;
  }[];
}

const GroupCard: React.FC<GroupCardProps> = ({ title, data }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [formData, setFormData] = useState({
    shortName: "",
    fullName: "",
    role: "",
    category: "",
    password: "",
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handleSubmit = () => {
    // add institution logic here
    console.log(formData);
  };

  const roles = [
    {
      id: 1,
      name: "admin",
    },
    {
      id: 1,
      name: "admin",
    },
    {
      id: 1,
      name: "admin",
    },
    {
      id: 1,
      name: "admin",
    },
    {
      id: 1,
      name: "admin",
    },
  ];

  const categories = [
    {
      id: 1,
      name: "hi",
    },
    {
      id: 1,
      name: "hi",
    },
    {
      id: 1,
      name: "hi",
    },
    {
      id: 1,
      name: "hi",
    },
    {
      id: 1,
      name: "hi",
    },
    {
      id: 1,
      name: "hi",
    },
  ];

  return (
    <>
      <div className="card">
        <div className="card-header flex justify-between items-center">
          <h4 className="card-title">{title}</h4>
          <div>
            <button
              onClick={() => setIsOpen(true)}
              className="btn bg-primary/20 text-sm font-medium text-primary hover:text-white hover:bg-primary"
            >
              <i className="mgc_add_circle_line me-3"></i> Add
            </button>
          </div>
        </div>

        <div className="py-6">
          <div className="px-6">
            {data.map((item, index) => (
              <React.Fragment key={index}>
                <InstitutionCard
                  imgSrc={item.imgSrc}
                  shortName={item.shortName}
                  fullName={item.fullName}
                />
                <hr />
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
      <Modal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        title={`Add Institution in ${title}`}
        handleSubmit={handleSubmit}
      >
        <form className="flex flex-col gap-3" onSubmit={handleSubmit}>
          <div className="grid grid-cols-4 items-center gap-6">
            <label
              htmlFor="shortName"
              className="text-gray-800 text-sm font-medium inline-block mb-2"
            >
              Short Name
            </label>
            <div className="md:col-span-3">
              <input
                type="text"
                className="form-input"
                id="shortName"
                placeholder="Enter short name"
                value={formData.shortName}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <div className="grid grid-cols-4 items-center gap-6">
            <label
              htmlFor="fullName"
              className="text-gray-800 text-sm font-medium inline-block mb-2"
            >
              Full Name
            </label>
            <div className="md:col-span-3">
              <input
                type="text"
                className="form-input"
                id="fullName"
                placeholder="Enter full name"
                value={formData.fullName}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <div className="grid grid-cols-4 items-center gap-6">
            <label
              htmlFor="password"
              className="text-gray-800 text-sm font-medium inline-block mb-2"
            >
              Password
            </label>
            <div className="md:col-span-3">
              <input
                type="password"
                className="form-input"
                id="password"
                placeholder="Enter password"
                value={formData.password}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <div className="grid grid-cols-4 items-center gap-6">
            <label
              htmlFor="role"
              className="text-gray-800 text-sm font-medium inline-block mb-2"
            >
              Role
            </label>
            <div className="md:col-span-3">
              <select
                id="role"
                className="form-select"
                value={formData.role}
                onChange={handleChange}
                required
              >
                <option value="" disabled>
                  Select a Role
                </option>
                {roles.map((role) => (
                  <option key={role.id} value={role.id}>
                    {role.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="grid grid-cols-4 items-center gap-6">
            <label
              htmlFor="category"
              className="text-gray-800 text-sm font-medium inline-block mb-2"
            >
              Category
            </label>
            <div className="md:col-span-3">
              <select
                id="category"
                className="form-select"
                value={formData.category}
                onChange={handleChange}
                required
              >
                <option value="" disabled>
                  Select a Category
                </option>
                {categories.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default GroupCard;
