import React from "react";
import PageTitle from "../../components/PageTitle";
import Card from "../../components/UIElements/Card";
import Selections from "../../components/candidates/Selections";
import AddImage from "../../components/candidates/AddImage";
import AddForm from "../../components/candidates/AddForm";
import AddActions from "../../components/candidates/AddActions";

const AddCandidate: React.FC = () => {
  const cardsData = [
    {
      id: 1,
      count: 3947,
      label: "Total Tickets",
      bgColor: "bg-primary/25",
      iconClass: "mgc_tag_line text-4xl text-primary",
    },
    {
      id: 2,
      count: 624,
      label: "Pending Tickets",
      bgColor: "bg-yellow-100",
      iconClass: "mgc_alarm_2_line text-4xl text-yellow-500",
    },
    {
      id: 3,
      count: 3195,
      label: "Closed Tickets",
      bgColor: "bg-green-100",
      iconClass: "mgc_check_line text-4xl text-green-500",
    },
    {
      id: 4,
      count: 128,
      label: "Deleted Tickets",
      bgColor: "bg-red-100",
      iconClass: "mgc_delete_line text-4xl text-red-500",
    },
  ];

  return (
    <>
      <PageTitle pagetitle={"Add Candidate"} subtitle={"Candidates"} />
      <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-6">
        {cardsData.map((data) => (
          <Card
            key={data.id}
            count={data.count}
            label={data.label}
            bgColor={data.bgColor}
            iconClass={data.iconClass}
          />
        ))}
      </div>
      <div className="grid lg:grid-cols-4 gap-6 mt-6">
        <div className="col-span-1 flex flex-col gap-6">
          <AddImage />
          <Selections />
        </div>
        <AddForm />
        <AddActions />
      </div>
    </>
  );
};

export default AddCandidate;
