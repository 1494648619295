const FileCard: React.FC<{ name: string }> = ({ name }) => {
  return (
    <div className="p-2 border border-gray-200 rounded mb-2">
      <div className="flex items-center">
        <div className="h-9 w-9 rounded flex justify-center items-center text-primary bg-primary/20 me-3">
          <i className="mgc_file_new_line text-xl"></i>
        </div>
        <div>
          <a href="#" className="text-sm font-medium">
            {name}
          </a>
        </div>
        <div>
          <a href="#" className="p-2">
            <i className="mgc_download_line text-xl"></i>
          </a>
        </div>
      </div>
    </div>
  );
};

export default FileCard;
