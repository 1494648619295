import React from "react";

interface CardHeaderProps {
  title: string;
  status: string;
  statusType: "success" | "warning" | "error";
}

const CardHeader: React.FC<CardHeaderProps> = ({ title, status, statusType }) => {
  const statusClasses = {
    success: "bg-success text-xs text-white rounded-md py-1 px-1.5 font-medium",
    warning: "bg-warning/60 text-xs text-white rounded-md py-1 px-1.5 font-medium",
    error: "bg-error text-xs text-white rounded-md py-1 px-1.5 font-medium",
  };

  return (
    <div className="card-header">
      <div className="flex justify-between items-center">
        <h5 className="card-title">{title}</h5>
        <div className={statusClasses[statusType]} role="alert">
          <span>{status}</span>
        </div>
      </div>
    </div>
  );
};

export default CardHeader;
