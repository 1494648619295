const Candidate: React.FC<{
  imgSrc: string;
  name: string;
  email: string;
  task: string;
  status: string;
  statusColor: string;
}> = ({ imgSrc, name, email, task, status, statusColor }) => {
  return (
    <div className="p-3">
      <div className="flex items-center gap-3">
        <div className="h-10 w-10 flex-shrink-0">
          <img className="h-10 w-10 rounded-full" src={imgSrc} alt={name} />
        </div>
        <div className="flex-grow truncate">
          <div className="font-medium text-gray-900">{name}</div>
          <p className="text-gray-600">{email}</p>
        </div>
        <div className="px-3 py-1 md:block hidden rounded text-xs font-medium">
          {task}
        </div>
        <div className="ms-auto">
          <div
            className={`px-3 py-1 rounded text-xs font-medium bg-${statusColor}/25 text-${statusColor}`}
          >
            {status}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Candidate;
