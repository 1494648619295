import React, { useState } from "react";
import PageTitle from "../../components/PageTitle";
import AddAdmin from "../../components/admin/AddAdmin";
import ShowAdmin from "../../components/admin/ShowAdmin";
import { useQuery } from "@apollo/client";
import { GET_USERS } from "../../graphql/queries/admin";

const Admin: React.FC = () => {
  const [editing, setEditing] = useState<any>(null);

  const { data, refetch } = useQuery(GET_USERS);

  const handleSaved = () => {
    setEditing(null); // Reset editing state
    refetch();
  };

  const handleEdit = (data: any) => {
    setEditing(data);
  };

  return (
    <>
      <PageTitle pagetitle={"Admins"} subtitle={"Admin"} />
      <div className="flex flex-col space-y-4">
        <div className="grid md:grid-cols-2 gap-6">
          <AddAdmin editingAdmin={editing} onSaved={handleSaved} />
          <ShowAdmin data={data} onEdit={handleEdit} />
        </div>
      </div>
    </>
  );
};

export default Admin;
