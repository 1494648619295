import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import ToggleButton from "../../components/ToggleButton";
import toast from "react-hot-toast";
import PageTitle from "../../components/PageTitle";
import { GET_PERMISSIONS } from "../../graphql/queries/permmission";
import { UPDATE_PERMISSION } from "../../graphql/mutations/permmission";

const Functions: React.FC = () => {
  const { data, refetch } = useQuery(GET_PERMISSIONS);
  const [updatePermission] = useMutation(UPDATE_PERMISSION);
  const [searchTerm, setSearchTerm] = useState("");

  const handleToggle = async (id: string, name: string, isActive: boolean) => {
    try {
      await updatePermission({
        variables: {
          id,
          name,
          isActive: !isActive,
        },
      });
      toast.success("Permission status updated!");
      refetch(); // Refetch to get the updated data
    } catch (err) {
      console.error("Failed to update permission status:", err);
      toast.error("Failed to update permission status.");
    }
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const filteredPermissions = data?.permissions.filter((permission: any) =>
    permission.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      <PageTitle pagetitle={"Functions"} subtitle={"Settings"} />
      <div className="flex flex-col space-y-4">
        {/* Search Bar */}
        <div className="overflow-hidden rounded-xl bg-white transition-all dark:bg-slate-800">
          <div className="relative">
            <div className="pointer-events-none absolute top-3.5 start-4 text-gray-900 text-opacity-40 dark:text-gray-200">
              <i className="mgc_search_line text-xl"></i>
            </div>
            <input
              type="search"
              className="h-12 w-full border-0 bg-transparent ps-11 pe-4 text-gray-900 placeholder-gray-500 dark:placeholder-gray-300 dark:text-gray-200 focus:ring-0 sm:text-sm"
              placeholder="Search..."
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>
        </div>

        {/* Permissions List */}
        <div className="grid md:grid-cols-3 xl:grid-cols-4 gap-6">
          {filteredPermissions?.map((permission: any) => (
            <div key={permission.id} className="card flex justify-around p-5">
              <p className="text-xl">{permission.name}</p>
              <div className="">
                <ToggleButton
                  isActive={permission.isActive}
                  onToggle={() =>
                    handleToggle(
                      permission.id,
                      permission.name,
                      permission.isActive
                    )
                  }
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Functions;
