import React from "react";
import CardHeader from "./CardHeader";
import CardBody from "./CardBody";
import CardFooter from "./CardFooter";

interface CardProps {
  title: string;
  status: string;
  statusType: "success" | "warning" | "error";
  taskTitle: string;
  taskDescription: string;
  avatars: string[];
  date: string;
  comments: number;
  tasks: number;
  progress: number; // progress in percentage
}

const Card: React.FC<CardProps> = ({
  title,
  status,
  statusType,
  taskTitle,
  taskDescription,
  avatars,
  date,
  comments,
  tasks,
  progress,
}) => {
  return (
    <div className="card">
      <CardHeader title={title} status={status} statusType={statusType} />
      <CardBody
        taskTitle={taskTitle}
        taskDescription={taskDescription}
        avatars={avatars}
      />
      <CardFooter date={date} comments={comments} tasks={tasks} progress={progress} />
    </div>
  );
};

export default Card;
