import React from "react";
import Card from "../../components/UIElements/Card";
import GroupCard from "../../components/institution/GroupCard";
import image from "../../assets/images/users/avatar-1.jpg";
import PageTitle from "../../components/PageTitle";

const Institution: React.FC = () => {
  const cardsData = [
    {
      id: 1,
      count: 3947,
      label: "Total Tickets",
      bgColor: "bg-primary/25",
      iconClass: "mgc_tag_line text-4xl text-primary",
    },
    {
      id: 2,
      count: 624,
      label: "Pending Tickets",
      bgColor: "bg-yellow-100",
      iconClass: "mgc_alarm_2_line text-4xl text-yellow-500",
    },
    {
      id: 3,
      count: 3195,
      label: "Closed Tickets",
      bgColor: "bg-green-100",
      iconClass: "mgc_check_line text-4xl text-green-500",
    },
    {
      id: 4,
      count: 128,
      label: "Deleted Tickets",
      bgColor: "bg-red-100",
      iconClass: "mgc_delete_line text-4xl text-red-500",
    },
  ];

  const groupData = [
    {
      imgSrc: image,
      shortName: "DHDC MANOOR",
      fullName: "Darul Hidaya Da'wa College",
    },
    {
      imgSrc: image,
      shortName: "DHDC MANOOR",
      fullName: "Darul Hidaya Da'wa College",
    },
    {
      imgSrc: image,
      shortName: "DHDC MANOOR",
      fullName: "Darul Hidaya Da'wa College",
    },
    {
      imgSrc: image,
      shortName: "DHDC MANOOR",
      fullName: "Darul Hidaya Da'wa College",
    },
    {
      imgSrc: image,
      shortName: "DHDC MANOOR",
      fullName: "Darul Hidaya Da'wa College",
    },
  ];

  return (
    <>
      <PageTitle pagetitle={"Institution"} subtitle={"Institution"} />
      <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-6">
        {cardsData.map((data) => (
          <Card
            key={data.id}
            count={data.count}
            label={data.label}
            bgColor={data.bgColor}
            iconClass={data.iconClass}
          />
        ))}
      </div>
      <div className="grid 2xl:grid-cols-4 md:grid-cols-3 gap-6 mt-6">
        <GroupCard title="GROUP A" data={groupData} />
        <GroupCard title="GROUP B" data={groupData} />
        <GroupCard title="GROUP C" data={groupData} />
      </div>
    </>
  );
};

export default Institution;
