import React from "react";
import Card from "./Card";
import AddButton from "./AddButton";

const Mark: React.FC = () => {
  const marks = [
    { title: "1st Position", candidates: "400+" },
    { title: "B Grade", candidates: "3000+" },
  ];

  const renderCard = (
    key: number,
    item: { title: string; candidates?: string; programs?: string }
  ) => (
    <Card
      key={key}
      title={item.title}
      candidates={item.candidates || ""}
      programs={item.programs || ""}
      textClass="text-base"
    />
  );

  return (
    <div className="bg-white p-4 rounded-lg">
      <div className="flex justify-between items-center mb-2">
        <h2 className="text-xl">Mark</h2>
        <AddButton text="Add Mark" />
      </div>
      <div className="space-y-4 bg-gray-100 rounded-xl overflow-hidden px-3 py-4">
        {marks.map((mark,index) => renderCard(index, mark))}
      </div>
    </div>
  );
};

export default Mark;
