import React from "react";
import darkLogo from "../../assets/images/logo.png";
import lightLogo from "../../assets/images/logo.png";
import smallLogo from "../../assets/images/logo.png";

const Logo: React.FC = () => (
  <a href="index.php" className="logo-box">
    {/* Light Brand Logo */}
    <div className="logo-light">
      <img src={lightLogo} className="logo-lg h-6" alt="Light logo" />
      <img src={smallLogo} className="logo-sm" alt="Small logo" />
    </div>
    {/* Dark Brand Logo */}
    <div className="logo-dark">
      <img src={darkLogo} className="logo-lg h-6" alt="Dark logo" />
      <img src={smallLogo} className="logo-sm" alt="Small logo" />
    </div>
  </a>
);

export default Logo;
