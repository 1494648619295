import React, { MouseEvent } from "react";

const FullscreenToggleButton: React.FC = () => {

  const handleFullScreenToggle = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    document.body.classList.toggle("fullscreen-enable");

    const docElement = document.documentElement as HTMLElement;

    if (!document.fullscreenElement) {
      if (docElement.requestFullscreen) {
        docElement.requestFullscreen();
      } else if (docElement.mozRequestFullScreen) {
        docElement.mozRequestFullScreen();
      } else if (docElement.webkitRequestFullscreen) {
        docElement.webkitRequestFullscreen();
      } else if (docElement.msRequestFullscreen) {
        docElement.msRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
  };

  return (
    <div className="md:flex hidden">
      <button
        data-toggle="fullscreen"
        type="button"
        className="nav-link p-2"
        onClick={handleFullScreenToggle}
      >
        <span className="sr-only">Fullscreen Mode</span>
        <span className="flex items-center justify-center h-6 w-6">
          <i className="mgc_fullscreen_line text-2xl"></i>
        </span>
      </button>
    </div>
  );
};

export default FullscreenToggleButton;
