import React, { useState, useRef, useEffect } from "react";
import GeneralIcon from "../../assets/images/general.png";
import NIICSIcon from "../../assets/images/niics.png";
import { useAuth } from "../../context/AuthContext";
import { useQuery } from "@apollo/client";
import { GET_SECTIONS } from "../../graphql/queries/section";

interface Section {
  id: string;
  sectionName: string;
}

const SectionDropdown: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [index, setIndex] = useState(0);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const { user, setUser } = useAuth();

  // GraphQL query to fetch sections
  const { data, loading, error } = useQuery(GET_SECTIONS);

  // Toggle dropdown visibility
  const toggleDropdown = () => setIsOpen(!isOpen);

  // Close dropdown when clicking outside
  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  // Handle section selection
  const handleSection = (
    e: React.MouseEvent,
    index: number
  ) => {
    e.preventDefault();
    setIsOpen(false);
    setIndex(index);
    const selectedsection = data.sections[index];
    setUser((prevUser: any) => ({
      ...prevUser,
      section: selectedsection,
    }));
    localStorage.setItem("section", selectedsection.id);
  };

  useEffect(() => {
    // Initialize the selected section based on the user's section
    if (!user?.section && data?.sections?.length) {
      const firstSection = data.sections[0];
      setUser((prevUser: any) => ({
        ...prevUser,
        section: firstSection,
      }));
    }
    localStorage.setItem("section", user?.section?.id);

    // Add event listener for clicks outside the dropdown
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [user, data]);

  if (loading) return <div>Loading sections...</div>;
  if (error) return <div>Error loading sections</div>;

  return (
    <div className="relative">
      <button
        type="button"
        className="nav-link p-2 fc-dropdown"
        onClick={toggleDropdown}
      >
        <span className="flex items-center justify-center h-6 w-6">
          <img
            src={index === 0 ? GeneralIcon : NIICSIcon}
            alt="user-flag"
            className="h-4 w-6"
          />
        </span>
      </button>
      <div
        ref={dropdownRef}
        className={`absolute -left-[120px] ${
          isOpen ? "block" : "hidden"
        } w-40 z-50 transition-[margin,opacity] duration-300 bg-white dark:bg-gray-800 shadow-lg border border-gray-200 dark:border-gray-700 rounded-lg p-2`}
      >
        {data?.sections.map((section: Section, index: number) => (
          <a
            key={section.id}
            href="#"
            onClick={(e) => handleSection(e, index)}
            className="flex items-center gap-2.5 py-2 px-3 cursor-pointer rounded-md text-sm text-gray-800 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300"
          >
            <img
              src={index === 0 ? GeneralIcon : NIICSIcon}
              alt={`${section.sectionName}-icon`} 
              className="h-4"
            />
            <span className="align-middle">{section.sectionName}</span>
          </a>
        ))}
      </div>
    </div>
  );
};

export default SectionDropdown;
