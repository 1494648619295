import ProgramInfo from "./ProgramInfo";

const ProgramDetails: React.FC = () => {
  return (
    <div className="grid grid-cols-4 gap-6">
      <ProgramInfo
        label="Start Date"
        value="15 July, 2019"
        icon="uil-calender"
      />
      <ProgramInfo
        label="Due Date"
        value="21 Nov, 2020"
        icon="uil-calendar-slash"
      />
      <ProgramInfo label="Budget" value="$13,250" icon="uil-dollar-alt" />
      <ProgramInfo label="Owner" value="Rick Perry" icon="uil-user" />
    </div>
  );
};

export default ProgramDetails;
