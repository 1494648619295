import { gql } from "@apollo/client";

export const GET_USERS = gql`
  query GetUsers {
    users {
      id
      username
      institution {
        id
        name
      }
      category {
        id
        name
      }
      section {
        id
        sectionName
      }
      group {
        id
        name
      }
      role {
        id
        name
        section
        group
        category
        institution
      }
    }
  }
`;

export const GET_USER = gql`
  query GetUser {
    getUser {
      id
      username
      role {
        name
        permissions {
          name
          isActive
        }
        section
      }
      section {
        id
        sectionName
      }
    }
  }
`;
