import React, { useEffect, useRef } from "react";

interface ModelProps {
  isOpen: boolean;
  title: string;
  children: React.ReactNode; // Corrected children type
  setIsOpen: (isOpen: boolean) => void;
  handleSubmit: () => void;
}

const Modal: React.FC<ModelProps> = ({
  isOpen,
  setIsOpen,
  title,
  children,
  handleSubmit
}) => {
  const modalRef = useRef<HTMLDivElement | null>(null);

  // Close the modal
  const handleClose = () => setIsOpen(false);

  // Handle click outside the modal to close it
  const handleClickOutside = (event: MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      handleClose();
    }
  };

  // Add event listener on mount and remove on unmount
  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <>
      {isOpen && (
        <div className="fixed top-0 left-0 z-50 w-full h-full bg-gray-900 bg-opacity-50 flex items-center justify-center transition-opacity duration-500 ease-out">
          <div
            ref={modalRef}
            className="w-full sm:max-w-lg bg-white border shadow-sm rounded-md dark:bg-slate-800 dark:border-gray-700 transition-transform transform duration-300"
          >
            <div className="flex justify-between items-center py-2.5 px-4 border-b dark:border-gray-700">
              <h3 className="font-medium text-gray-800 dark:text-white text-lg">
                {title} {/* Using the title prop */}
              </h3>
              <button
                className="inline-flex flex-shrink-0 justify-center items-center h-8 w-8 dark:text-gray-200"
                type="button"
                onClick={handleClose}
              >
                <span className="material-symbols-rounded">close</span>
              </button>
            </div>
            <div className="px-4 py-8 overflow-y-auto">
              {/* Render children */}
              {children}
            </div>
            <div className="flex justify-end items-center gap-4 p-4 border-t dark:border-slate-700">
              <button
                className="py-2 px-5 inline-flex justify-center items-center gap-2 rounded dark:text-gray-200 border dark:border-slate-700 font-medium hover:bg-slate-100 hover:dark:bg-slate-700 transition-all"
                type="button"
                onClick={handleClose}
              >
                Close
              </button>
              <a
                className="py-2.5 px-4 inline-flex justify-center items-center gap-2 cursor-pointer rounded bg-primary hover:bg-primary-600 text-white"
                onClick={handleSubmit}
              >
                Save
              </a>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
