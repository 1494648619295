import React from "react";

interface AddButtonProps{
    text: string;
}

const AddButton: React.FC<AddButtonProps> = ({text}) => {
    return (
        <button className="bg-gray-200 px-3 py-1 text-xs text-blue-600 rounded hover:bg-blue-100">
            <i className="mgc_add_circle_line text-sm pr-2"></i>
            {text}
        </button>
    )
}

export default AddButton;