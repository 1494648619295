import React from "react";
import AddButton from "./AddButton";
import Card from "./Card";
import ReactApexChart from "react-apexcharts";

interface CardStructureProps {
    title: string;
    addButton: string;
    data: {
        title: string;
        candidates?: string;
        programs?: string;
    }[];
    chartOptions: {
        labels: string[];
        colors: string[];
        legend: {
            position: "top" | "right" | "bottom" | "left" | undefined;
        };
        responsive: {
                breakpoint: number;
                options: {
                    chart: {
                        width: number;
                    };
                    legend: {
                        position: string;
                    };
                };
            }[];
    };
    chartSeries: number[];
}

const CardStructure: React.FC<CardStructureProps> = ({
    title,
    addButton,
    data,
    chartOptions,
    chartSeries,
}) => {
    const renderCard = (item: { title: string; candidates?: string; programs?: string }) => (
        <Card
            title={item.title}
            candidates={item.candidates || ""}
            programs={item.programs || ""}
            textClass="text-sm"
        />
    );

    return (
        <div className="bg-white p-4 shadow rounded-lg">
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-base">{title}</h2>
                <AddButton text={addButton} />
            </div>

            <div className="space-y-4 bg-gray-100 rounded-xl overflow-hidden px-2 py-4">
                {data.map((group, index) => (
                    <div key={index}>{renderCard(group)}</div>
                ))}
            </div>

            <div className="apex-charts my-8">
                <ReactApexChart
                    id="Groups"
                    options={chartOptions}
                    series={chartSeries}
                    type="donut"
                    height={350}
                />
            </div>
        </div>
    );
};

export default CardStructure;
