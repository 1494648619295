import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import darkLogo from "../../assets/images/logo.png";
import lightLogo from "../../assets/images/logo.png";
import { useMutation } from "@apollo/client";
import { LOGIN_MUTATION } from "../../graphql/mutations/user";
import toast from "react-hot-toast";
import { useAuth } from "../../context/AuthContext";

const Login: React.FC = () => {
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [loginMutation, { loading }] = useMutation(LOGIN_MUTATION);
  const { login } = useAuth(); 

  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!username || !password) {
      toast.error("Username and password are required.");
      return;
    }

    try {
      const { data } = await loginMutation({ variables: { username, password } });
      if (data) {
        const { token, user } = data.login;
        
        login(token, user); 
        toast.success("Login successful!");
        navigate("/");
      }
    } catch (error: any) {
      const errorMessage =
        error.graphQLErrors && error.graphQLErrors[0]?.message
          ? error.graphQLErrors[0]?.message
          : "Failed to login";
      toast.error(errorMessage);
    }
  };

  return (
    <div className="bg-gradient-to-r from-rose-100 to-teal-100 dark:from-gray-700 dark:via-gray-900 dark:to-black">
      <div className="h-screen w-screen flex justify-center items-center">
        <div className="2xl:w-1/4 lg:w-1/4 md:w-1/2 w-full">
          <div className="card overflow-hidden sm:rounded-md rounded-none">
            <form className="p-6" onSubmit={handleSubmit}>
              <a href="/" className="block mb-8">
                <img
                  className="h-6 block dark:hidden"
                  src={darkLogo}
                  alt="Logo"
                />
                <img
                  className="h-6 hidden dark:block"
                  src={lightLogo}
                  alt="Logo"
                />
              </a>

              <div className="mb-4">
                <label
                  className="block text-sm font-medium text-gray-600 dark:text-gray-200 mb-2"
                  htmlFor="LoggingUsername"
                >
                  Username
                </label>
                <input
                  id="LoggingUsername"
                  name="username"
                  className="form-input"
                  type="text"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  placeholder="Enter your username"
                  required
                />
              </div>

              <div className="mb-4">
                <label
                  className="block text-sm font-medium text-gray-600 dark:text-gray-200 mb-2"
                  htmlFor="loggingPassword"
                >
                  Password
                </label>
                <input
                  id="loggingPassword"
                  className="form-input"
                  type="password"
                  name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Enter your password"
                  required
                />
              </div>

              <div className="flex justify-center mb-6">
                <button
                  className="btn w-full text-white bg-primary"
                  type="submit"
                  disabled={loading}
                >
                  {loading ? "Logging in..." : "Login"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
