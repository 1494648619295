import React from "react";
import { Droppable, DroppableProvided } from "@hello-pangea/dnd";

interface KanbanBoardProps {
  data: {
    id: string;
    name: string;
  }[];
  name: string;
  index: number;
  isOpen: boolean;
  setIsOpen: (index: number) => void;
}

const KanbanBoard: React.FC<KanbanBoardProps> = ({
  data,
  name,
  index,
  isOpen,
  setIsOpen,
}) => {
  return (
    <Droppable droppableId={index.toString()}>
      {(provided: DroppableProvided) => (
        <div
          className="flex flex-col flex-shrink-0 h-[min-content] border rounded-md overflow-hidden border-gray-200 dark:border-gray-700"
          ref={provided.innerRef}
          {...provided.droppableProps}
        >
          <div className="flex justify-between items-center flex-shrink-0 h-10 p-4 bg-white dark:bg-slate-800">
            <span className="block text-sm font-semibold uppercase ">
              {name} ({data.length})
            </span>
            <span onClick={() => setIsOpen(index)} className="cursor-pointer">
              {isOpen ? (
                <i className="mgc_down_line text-2xl"></i>
              ) : (
                <i className="mgc_up_line text-2xl"></i>
              )}
            </span>
          </div>
          <div
            className={`${
              isOpen ? "flex" : "hidden"
            } flex-col gap-4 overflow-auto p-4 h-[calc(100vh-300px)] kanban-board custom-scroll`}
            id="kanbanborad-two"
          >
            {/** Placeholder for the dropped items */}
            {data.map((data, index) => (
              <div className="card p-4 cursor-pointer" key={index}>
                <div className="flex justify-between items-center">
                  <h4 className="text-sm">{data.name}</h4>
                  <button
                    className="inline-flex flex-shrink-0 justify-center items-center h-8 w-8 dark:text-gray-200"
                    type="button"
                  >
                    <span className="material-symbols-rounded">close</span>
                  </button>
                </div>
              </div>
            ))}

            {provided.placeholder}
          </div>
        </div>
      )}
    </Droppable>
  );
};

export default KanbanBoard;
