import { gql } from '@apollo/client';

export const GET_PERMISSIONS = gql`
  query GetPermissions {
    permissions {
      id
      name
      isActive
    }
  }
`;
