import React, { useState } from "react";

const Selections: React.FC = () => {
  const [selectedCategory, setSelectedCategory] = useState("");

  return (
    <div className="card p-6">
      <div className="flex justify-between items-center mb-4">
        <p className="card-title">Team Members</p>
        <div className="inline-flex items-center justify-center rounded-lg bg-slate-100 dark:bg-slate-700 w-9 h-9">
          <i className="mgc_compass_line"></i>
        </div>
      </div>

      <div className="flex flex-col gap-3">
        <div>
          <label htmlFor="select-label-category" className="mb-2 block">
            Category
          </label>
          <select
            id="select-label-category"
            className="form-select"
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
          >
            <option>Select</option>
            <option>Mary Scott</option>
            <option>Holly Campbell</option>
            <option>Melinda Gills</option>
            <option>Linda Garza</option>
          </select>
        </div>

        <div className="flex gap-3">
          <div className="flex -space-x-2">
            <img
              className="inline-block h-8 w-8 rounded-full ring-2 ring-gray-200 dark:ring-gray-700"
              src="assets/images/users/user-9.jpg"
              alt="Image Description"
            />
            <img
              className="inline-block h-8 w-8 rounded-full ring-2 ring-gray-200 dark:ring-gray-700"
              src="assets/images/users/user-8.jpg"
              alt="Image Description"
            />
            <img
              className="inline-block h-8 w-8 rounded-full ring-2 ring-gray-200 dark:ring-gray-700"
              src="assets/images/users/user-7.jpg"
              alt="Image Description"
            />
            <img
              className="inline-block h-8 w-8 rounded-full ring-2 ring-gray-200 dark:ring-gray-700"
              src="assets/images/users/user-6.jpg"
              alt="Image Description"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Selections;
