import React  from "react";

const AddActions: React.FC = () => {

  return (
    <div className="lg:col-span-4 mt-5">
      <div className="flex justify-end gap-3">
        <button
          type="button"
          className="inline-flex items-center rounded-md border border-transparent bg-red-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none"
        >
          Cancel
        </button>
        <button
          type="button"
          className="inline-flex items-center rounded-md border border-transparent bg-green-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-green-500 focus:outline-none"
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default AddActions;
