import React from "react";
import { useQuery } from "@apollo/client";
import { GET_SECTIONS } from "../../graphql/queries/section";

interface SectionInputProps {
  section: string;
  setSection: (section: string) => void;
}

const SectionInput: React.FC<SectionInputProps> = ({ section, setSection }) => {
  // Fetch sections data using Apollo Client
  const { data, loading, error } = useQuery(GET_SECTIONS);

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSection(e.target.value);
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading sections</p>;

  return (
    <div className="mb-3">
      <label
        htmlFor="sectionSelect"
        className="text-gray-800 text-sm font-medium inline-block mb-2"
      >
        Select Section
      </label>
      <select
        id="sectionSelect"
        className="form-select"
        value={section}
        onChange={handleChange}
        required
      >
        <option value="" disabled>
          Select a Section
        </option>
        {data.sections.map((section: any) => (
          <option key={section.id} value={section.id}>
            {section.sectionName}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SectionInput;
