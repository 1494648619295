import React, { useEffect, useState } from "react";
import PageTitle from "../../components/PageTitle";
import KanbanBoard from "../../components/UIElements/KanbanBoard";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "@hello-pangea/dnd";

const CrubList: React.FC = () => {
  const [programList] = useState(
    [...Array(20)].map((_, index) => ({
      id: index.toString(),
      name: `Program ${index + 1}`,
    }))
  );

  const DATA = [
    {
      id: "1",
      name: "MLM",
      programs: [
        {
          id: "1",
          name: "Song MLM",
        },
        {
          id: "2",
          name: "Song MLM",
        },
      ],
    },
    {
      id: "2",
      name: "MLM",
      programs: [
        {
          id: "1",
          name: "Song MLM",
        },
        {
          id: "2",
          name: "Song MLM",
        },
      ],
    },
    {
      id: "3",
      name: "MLM",
      programs: [
        {
          id: "1",
          name: "Song MLM",
        },
        {
          id: "2",
          name: "Song MLM",
        },
      ],
    },
  ];

  const [data, setData] = useState(DATA);
  const [isOpen, setIsOpen] = useState(data.map(() => false));

  useEffect(() => {
    document.body.classList.add("no-scroll");
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, []);

  const handleDragEnd = (result: DropResult) => {
    console.log(result);
    const { destination, source } = result;

    if (!destination) return;
    if (source.droppableId == destination.droppableId) return;

    // Get the moved item from programList
    const [movedItem] = programList.splice(source.index, 1);

    // Clone the data to avoid mutating state directly
    const updatedData = [...data];

    // Add the movedItem to the programs array of the destination in data
    updatedData[parseInt(destination.droppableId)].programs.splice(
      destination.index,
      0,
      movedItem
    );

    // Update the data state with the modified programs array
    setData(updatedData);
  };

  const updateIsOpen = (index: number) => {
    setIsOpen((prev) => prev.map((open, i) => (i === index ? !open : open)));
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <div className="h-screen">
        <PageTitle pagetitle={"Crub List"} subtitle={"Programs"} />
        <div className="grid md:grid-cols-3 grid-cols-1 gap-6 h-full">
          <div className="col-span-1 flex flex-col h-full overflow-y-auto">
            <div className="pb-2 border-b border-gray-200 dark:border-gray-700">
              <input
                type="search"
                className="h-10 w-full border-gray-300 ps-4 rounded-xl pe-4 text-gray-900 placeholder-gray-500 dark:placeholder-gray-300 dark:text-gray-200 focus:ring-0 sm:text-sm"
                placeholder="Search..."
              />
              <div className="grid grid-cols-2 mt-4 gap-2">
                <input
                  type="search"
                  className="h-10 w-full border-gray-300 ps-4 rounded-xl pe-4 text-gray-900 placeholder-gray-500 dark:placeholder-gray-300 dark:text-gray-200 focus:ring-0 sm:text-sm"
                  placeholder="Search..."
                />
                <input
                  type="search"
                  className="h-10 w-full border-gray-300 ps-4 rounded-xl pe-4 text-gray-900 placeholder-gray-500 dark:placeholder-gray-300 dark:text-gray-200 focus:ring-0 sm:text-sm"
                  placeholder="Search..."
                />
              </div>
            </div>

            <Droppable droppableId="programList">
              {(provided) => (
                <div
                  className="program_list mt-4 space-y-4 overflow-y-auto h-full no-scrollbar pb-40"
                  ref={provided.innerRef}
                  {...provided.droppableProps}  
                >
                  {programList.map((program, index) => (
                    <Draggable
                      key={program.id}
                      draggableId={program.id}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          className="card p-4 cursor-pointer"
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <div className="flex justify-between items-center">
                            <h4 className="text-sm">{program.name}</h4>
                            <button
                              className="inline-flex flex-shrink-0 justify-center items-center h-8 w-8 dark:text-gray-200"
                              type="button"
                            >
                              <span className="material-symbols-rounded">
                                close
                              </span>
                            </button>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>

          <div className="grid place-content-start 2xl:grid-cols-3 md:grid-cols-2 grid-cols-1 col-span-2 gap-6 overflow-y-auto no-scrollbar pb-40">
            {data.map((data, index) => (
              <KanbanBoard
                data={data.programs}
                name={data.name}
                key={index}
                index={index}
                isOpen={isOpen[index]}
                setIsOpen={updateIsOpen}
              />
            ))}
          </div>
        </div>

      </div>
    </DragDropContext>
  );
};

export default CrubList;
