const AssignedUser: React.FC<{ imgSrc: string }> = ({ imgSrc }) => {
  return (
    <a href="javascript: void(0);">
      <img
        className="inline-block h-10 w-10 rounded-full border-2 border-white"
        src={imgSrc}
        alt="User Avatar"
      />
    </a>
  );
};

export default AssignedUser;
