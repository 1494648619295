import React from "react";
import { NavLink } from "react-router-dom";

interface MenuItemProps {
  to: string;
  iconClass?: string;
  text: string;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
}

const MenuItem: React.FC<MenuItemProps> = ({
  to,
  iconClass,
  text,
  onClick,
}) => {
  return (
    <li className="menu-item">
      <NavLink
        to={to}
        className="menu-link"
        // activeClassName="active" // Add active class when the route matches
        onClick={onClick}
      >
        {iconClass && (
          <span className="menu-icon">
            <i className={iconClass}></i>
          </span>
        )}
        <span className="menu-text">{text}</span>
      </NavLink>
    </li>
  );
};

export default MenuItem;
