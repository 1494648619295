// ApolloProvider.tsx
import React from "react";
import { ApolloProvider, ApolloClient, InMemoryCache, HttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

const ApolloClientProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {

  const authLink = setContext((_, { headers }) => {
    const token = localStorage.getItem("token");
    const sectionId = localStorage.getItem("section");
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
        sectionId
      },
    };
  });

  const httpLink = new HttpLink({
    uri: 'https://api.sibaq.in/graphql', // production
    // uri: 'https://rms-api-nine.vercel.app/graphql', // server
    // uri: "http://localhost:4000/graphql", // local
  });

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
    credentials: "include",
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default ApolloClientProvider;
