import React from "react";

interface InstitutionCardProps {
  imgSrc: string;
  shortName: string;
  fullName: string;
}

const InstitutionCard: React.FC<InstitutionCardProps> = ({
  imgSrc,
  shortName,
  fullName,
}) => {
  return (
    <div className="flex items-center">
      <img
        className="me-3 rounded-full"
        src={imgSrc}
        width="40"
        alt={shortName}
      />
      <div className="grid grid-cols-4 items-center w-full overflow-hidden">
        <div className="col-span-3">
          <h5 className="font-semibold">
            <a
              href="javascript:void(0);"
              className="text-gray-600 dark:text-gray-400"
            >
              {shortName}
            </a>
          </h5>
          <div className="flex items-center gap-2 text-xs">
            <div>{fullName}</div>
          </div>
        </div>
        <div className="whitespace-nowrap py-4 text-center text-sm font-medium">
          <a href="javascript:void(0);" className="me-0.5">
            {" "}
            <i className="mgc_edit_line text-lg"></i>{" "}
          </a>
          <a href="javascript:void(0);" className="ms-0.5">
            {" "}
            <i className="mgc_delete_line text-xl"></i>{" "}
          </a>
        </div>
      </div>
    </div>
  );
};

export default InstitutionCard;
