import React, { useState, useRef, useEffect } from "react";
import { useAuth } from "../../context/AuthContext";
import { useMutation } from "@apollo/client";
import {
  CHANGE_PASSWORD_MUTATION,
  LOGOUT_MUTATION,
} from "../../graphql/mutations/user";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import Modal from "../UIElements/Model";

const ProfileDropdown: React.FC = () => {
  const [isProfileDropdownOpen, setProfileDropdownOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const { user, logout } = useAuth();
  const [logoutMutation] = useMutation(LOGOUT_MUTATION);
  const navigate = useNavigate();

  // Toggle profile dropdown
  const toggleProfileDropdown = () =>
    setProfileDropdownOpen(!isProfileDropdownOpen);

  // Handle logout
  const handleLogout = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const { data } = await logoutMutation();
      if (data) {
        logout();
        toast.success("Logout successful!");
        navigate("/login");
      }
    } catch (error: any) {
      const errorMessage =
        error.graphQLErrors && error.graphQLErrors[0]?.message
          ? error.graphQLErrors[0]?.message
          : "An unknown error occurred";
      console.log(error);
      toast.error(errorMessage);
    }
  };

  // Handle change password (assuming this should be an actual function in your app)
  const handleChangePassword = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsOpen(true);
  };

  const [formData, setFormData] = useState({
    currentPassword: "",
    newPassword: "",
    rePassword: "",
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };
  const [changePassword] = useMutation(CHANGE_PASSWORD_MUTATION);
  const handleSubmit = async () => {
    // Handle form submission logic
    console.log(user.id);
    if (formData.newPassword === formData.rePassword) {
      try {
        await changePassword({
          variables: {
            id: user.id,
            password: formData.currentPassword,
            newPassword: formData.newPassword,
          },
        });
        toast.success("Password Changed");
        setFormData({
          currentPassword: "",
          newPassword: "",
          rePassword: "",
        });
        setIsOpen(false);
      } catch (error: any) {
        const errorMessage =
          error.graphQLErrors && error.graphQLErrors[0]?.message
            ? error.graphQLErrors[0]?.message
            : "Failed to change password.";
        console.error("Failed to change password:", error);
        toast.error(errorMessage);
      }
    } else {
      toast.error("New Passwords are not match");
    }
  };

  // Close dropdown when clicking outside
  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setProfileDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div className="relative">
      <button
        type="button"
        className="nav-link"
        onClick={toggleProfileDropdown}
      >
        <span className="flex items-center justify-center h-6 w-6 font-bold text-xl bg-blue-100 rounded-full text-gray-500 p-5">
          {user?.username.charAt(0).toUpperCase()}
        </span>
      </button>
      <div
        ref={dropdownRef}
        className={`absolute -left-[170px] ${
          isProfileDropdownOpen ? "block" : "hidden"
        } w-auto z-50 transition-opacity duration-300 mt-2 bg-white shadow-lg border rounded-lg p-2 border-gray-200 dark:border-gray-700 dark:bg-gray-800`}
      >
        <div className="flex justify-center mt-4">
          <span className="flex items-center justify-center h-6 w-6 font-bold text-3xl bg-blue-100 rounded-full text-gray-500 p-8">
            {user?.username.charAt(0).toUpperCase()}
          </span>
        </div>
        <div className="flex justify-center mt-2">
          <p className="text-base capitalize font-bold">{user?.username}</p>
        </div>

        <hr className="my-2 -mx-2 border-gray-200 dark:border-gray-700" />

        <div className="flex justify-center mt-2">
          <a
            className="relative flex items-center cursor-pointer p-3 mx-2 rounded-full text-gray-800 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300 group"
            href="/"
          >
            <span className="flex items-center justify-center h-6 w-6">
              <i className="mgc_user_1_line text-2xl"></i>
            </span>
            <span className="absolute top-full left-1/2 transform -translate-x-1/2 mb-2 hidden group-hover:block bg-gray-800 text-white text-sm rounded py-1 px-2 whitespace-no-wrap">
              Profile
            </span>
          </a>
          <a
            className="relative flex items-center cursor-pointer p-3 mx-2 rounded-full text-gray-800 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300 group"
            onClick={handleChangePassword}
          >
            <span className="flex items-center justify-center h-6 w-6">
              <i className="mgc_key_2_line text-2xl"></i>
            </span>
            <span className="absolute top-full left-1/2 transform -translate-x-1/2 mt-2 hidden group-hover:block bg-gray-800 text-white text-sm rounded py-1 px-2 whitespace-no-wrap">
              Change Password
            </span>
          </a>
          <Modal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            title="Change Your Password"
            handleSubmit={handleSubmit}
          >
            <form className="flex flex-col gap-3" onSubmit={handleSubmit}>
              <div className="grid grid-cols-4 items-center gap-6">
                <label
                  htmlFor="currentPassword"
                  className="text-gray-800 text-sm font-medium inline-block mb-2"
                >
                  Current Password
                </label>
                <div className="md:col-span-3">
                  <input
                    type="password"
                    className="form-input"
                    id="currentPassword"
                    placeholder="Current Password"
                    value={formData.currentPassword}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="grid grid-cols-4 items-center gap-6">
                <label
                  htmlFor="newPassword"
                  className="text-gray-800 text-sm font-medium inline-block mb-2"
                >
                  New Password
                </label>
                <div className="md:col-span-3">
                  <input
                    type="password"
                    className="form-input"
                    id="newPassword"
                    placeholder="New Password"
                    value={formData.newPassword}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="grid grid-cols-4 items-center gap-6">
                <label
                  htmlFor="rePassword"
                  className="text-gray-800 text-sm font-medium inline-block mb-2"
                >
                  Re-type New Password
                </label>
                <div className="md:col-span-3">
                  <input
                    type="password"
                    className="form-input"
                    id="rePassword"
                    placeholder="Retype Password"
                    value={formData.rePassword}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </form>
          </Modal>
          <a
            className="relative flex items-center cursor-pointer p-3 mx-2 rounded-full text-gray-800 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300 group"
            onClick={handleLogout}
          >
            <span className="flex items-center justify-center h-6 w-6">
              <i className="mgc_exit_line text-2xl"></i>
            </span>
            <span className="absolute top-full left-1/2 transform -translate-x-1/2 mt-2 hidden group-hover:block bg-gray-800 text-white text-sm rounded py-1 px-2 whitespace-no-wrap">
              Logout
            </span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default ProfileDropdown;
