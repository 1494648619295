import React from "react";
import Card from "./Card";
import AddButton from "./AddButton";

const Sections: React.FC = () => {
  const sections = [
    { title: "General", candidates: "4000+", programs: "280+" },
    { title: "NIICS", candidates: "3000+", programs: "280+" },
  ];

  const renderCard = (
    item: { title: string; candidates?: string; programs?: string },
    key: number
  ) => (
    <Card
      key={key}
      title={item.title}
      candidates={item.candidates || ""}
      programs={item.programs || ""}
      textClass="text-base"
    />
  );

  return (
    <div className="bg-white py-4 px-4 rounded-lg">
      <div className="flex justify-between items-center mb-2">
        <h2 className="text-xl">Sections</h2>
        <AddButton text="Add Sections" />
      </div>
      <div className="space-y-4 bg-gray-100 rounded-xl overflow-hidden px-3 py-4">
        {sections.map((section, index) => renderCard(section, index))}
      </div>
    </div>
  );
};

export default Sections;
