import { gql } from "@apollo/client";

export const UPDATE_PERMISSION = gql`
  mutation UpdatePermission($id: ID!, $name: String!, $isActive: Boolean) {
    updatePermission(id: $id, input: { name: $name, isActive: $isActive }) {
      id
      name
      isActive
    }
  }
`;
