import React from "react";
import CardStructure from "./CardStructure";

const Groups: React.FC = () => {
    const general = 82;
    const niics = 37;
    const chartSeries = [general, niics];

    const chartOptions = {
        labels: [`General Candidates `, `NIICS Candidates`],
        colors: ['#3073F1', '#0acf97'],
        legend: {
            position: "bottom" as const
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200
                },
                legend: {
                    position: "bottom"
                }
            }
        }],
    };

    const groups = [
        { title: 'Group A', candidates: '4000+' },
        { title: 'Group B', candidates: '5000+' },
        { title: 'Group C', candidates: '4000+' },
    ];

    return (
        <CardStructure
            title= "Groups"
            addButton="Add Group"
            data={groups}
            chartOptions={chartOptions}
            chartSeries={chartSeries}
        />
    )
}

export default Groups;