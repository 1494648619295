import React from "react";

interface CardBodyProps {
  taskTitle: string;
  taskDescription: string;
  avatars: string[];
}

const CardBody: React.FC<CardBodyProps> = ({ taskTitle, taskDescription, avatars }) => {
  return (
    <div className="flex flex-col">
      <div className="py-3 px-6">
        <h5 className="my-2">
          <a href="#" className="text-slate-900 dark:text-slate-200">
            {taskTitle}
          </a>
        </h5>
        <p className="text-gray-500 text-sm mb-9">{taskDescription}</p>

        <div className="flex -space-x-2">
          {avatars.map((avatar, index) => (
            <a key={index} href="#">
              <img
                className="inline-block h-12 w-12 rounded-full border-2 border-white dark:border-gray-700"
                src={avatar}
                alt="Avatar"
              />
            </a>
          ))}
          <a href="#">
            <div className="relative inline-flex">
              <button className="inline-flex items-center justify-center h-12 w-12 rounded-full bg-gray-200 border-2 border-white font-medium text-gray-700 shadow-sm align-middle dark:bg-gray-700 dark:text-gray-200 dark:border-gray-600 transition-all text-sm">
                <span className="font-medium leading-none">{avatars.length}+</span>
              </button>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default CardBody;
