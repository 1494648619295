import React from 'react';
import SectionGrid from './SectionGrid';
import Groups from './Groups';
import ProgramCategory from './ProgramCategory';
import Skill from './Skill';
import Category from './Category';

const GeneralContent: React.FC = () => {
    return (
        <div className="p-6 bg-gray-100 min-h-screen">
            <SectionGrid />
            <div className="grid grid-cols-1 lg:grid-cols-4 gap-6 mt-6">
                <Groups />
                <ProgramCategory />
                <Skill />
                <Category />
            </div>
        </div>
    );
};

export default GeneralContent;
