import React from "react";

interface CardProps {
    title: string;
    candidates: string;
    programs: string;
    textClass: string;
}

const Card: React.FC<CardProps> = ({ title, candidates, programs, textClass }) => {
    return (
        <div className="bg-gray-100 p-1 flex justify-between items-center">
            <div className="flex">
                <div className="w-10 h-10 bg-gray-400 rounded-md mr-3"></div>
                <div>
                    <div className={`${textClass} font-semibold text-gray-700`}>{title}</div>
                    {candidates && <div className="inline text-xs text-gray-500">{candidates} Candidates</div>}
                    {candidates && programs && <div className="inline"> &#x2022; </div>}
                    {programs &&
                            <div className="inline text-xs text-gray-500">{programs} Programs</div>}
                </div>
            </div>
            <div className="flex items-center">
                <button className="text-gray-400 hover:text-gray-700 text-xl">
                    <i className="mgc_edit_3_line"></i>
                </button>
            </div>
        </div>
    )
}

export default Card;