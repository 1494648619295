import React from "react";
import { Link } from "react-router-dom";

interface CustomerData {
  id: string;
  requestedBy: {
    name: string;
    avatar: string;
  };
  subject: string;
  assigneeAvatar: string;
  priority: "Low" | "Medium" | "High";
  status: "Open" | "Closed";
  createdDate: string;
  dueDate: string;
}

const customerData: CustomerData[] = [
  {
    id: "#1020",
    requestedBy: {
      name: "Lindsay Walton",
      avatar: "assets/images/users/avatar-9.jpg",
    },
    subject: "A new rating has been received",
    assigneeAvatar: "assets/images/users/avatar-9.jpg",
    priority: "Medium",
    status: "Closed",
    createdDate: "13/08/2011",
    dueDate: "30/08/2013",
  },
  {
    id: "#1254",
    requestedBy: {
      name: "Jhon Maryo",
      avatar: "assets/images/users/avatar-2.jpg",
    },
    subject: "Your application has been received!",
    assigneeAvatar: "assets/images/users/avatar-2.jpg",
    priority: "High",
    status: "Closed",
    createdDate: "01/04/2017",
    dueDate: "21/05/2017",
  },
];

const CandidatesTable: React.FC = () => {
  return (
    <div className="card">
      <div className="flex flex-wrap justify-between items-center gap-2 p-6">
        <Link
          to="/candidates/add"
          className="btn bg-danger/20 text-sm font-medium text-danger hover:text-white hover:bg-danger"
        >
          <i className="mgc_add_circle_line me-3"></i> Add Candidate
        </Link>
        <div className="flex flex-wrap gap-2">
          <div className="relative">
            <div className="pointer-events-none absolute top-3.5 start-4 text-gray-900 text-opacity-40 dark:text-gray-200">
              <i className="mgc_search_line text-xl"></i>
            </div>
            <input
              type="search"
              className="h-12 w-full border-1 rounded-lg bg-transparent ps-11 pe-4 text-gray-900 placeholder-gray-500 dark:placeholder-gray-300 dark:text-gray-200 focus:ring-0 sm:text-sm"
              placeholder="Search..."
            />
          </div>
          <button
            type="button"
            className="btn bg-dark/25 text-sm font-medium text-slate-900 dark:text-slate-200/70 hover:text-white hover:bg-dark/90"
          >
            Print
          </button>
          <button
            type="button"
            className="btn bg-dark/25 text-sm font-medium text-slate-900 dark:text-slate-200/70 hover:text-white hover:bg-dark/90"
          >
            Import
          </button>
          <button
            type="button"
            className="btn bg-dark/25 text-sm font-medium text-slate-900 dark:text-slate-200/70 hover:text-white hover:bg-dark/90"
          >
            Export
          </button>
        </div>
      </div>
      <div className="relative overflow-x-auto">
        <table className="w-full divide-y divide-gray-300 dark:divide-gray-700">
          <thead className="bg-slate-300 bg-opacity-20 border-t dark:bg-slate-800 divide-gray-300 dark:border-gray-700">
            <tr>
              <th
                scope="col"
                className="py-3.5 ps-4 pe-3 text-left text-sm font-semibold text-gray-900 dark:text-gray-200"
              >
                ID
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-200"
              >
                Requested By
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-200"
              >
                Subject
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-200"
              >
                Assignee
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-200"
              >
                Priority
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-200"
              >
                Status
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-200"
              >
                Created Date
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-200"
              >
                Due Date
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900 dark:text-gray-200"
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
            {customerData.map((customer) => (
              <tr key={customer.id}>
                <td className="whitespace-nowrap py-4 ps-4 pe-3 text-sm font-medium text-gray-900 dark:text-gray-200">
                  <b>{customer.id}</b>
                </td>
                <td className="whitespace-nowrap py-4 pe-3 text-sm">
                  <div className="flex items-center">
                    <div className="h-10 w-10 flex-shrink-0">
                      <img
                        className="h-10 w-10 rounded-full"
                        src={customer.requestedBy.avatar}
                        alt=""
                      />
                    </div>
                    <div className="font-medium text-gray-900 dark:text-gray-200 ms-4">
                      {customer.requestedBy.name}
                    </div>
                  </div>
                </td>
                <td className="whitespace-nowrap py-4 pe-3 text-sm font-medium text-gray-900 dark:text-gray-200">
                  {customer.subject}
                </td>
                <td className="whitespace-nowrap py-4 px-3 text-sm">
                  <img
                    className="h-10 w-10 rounded-full"
                    src={customer.assigneeAvatar}
                    alt=""
                  />
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  <div
                    className={`inline-flex items-center gap-1.5 py-1 px-3 rounded text-xs font-medium ${
                      customer.priority === "High"
                        ? "bg-danger/25 text-danger"
                        : "bg-primary/25 text-primary"
                    }`}
                  >
                    {customer.priority}
                  </div>
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  <div className="inline-flex items-center gap-1.5 py-1 px-3 rounded text-xs font-medium bg-dark/80 text-white">
                    {customer.status}
                  </div>
                </td>
                <td className="whitespace-nowrap py-4 pe-3 text-sm font-medium text-gray-900 dark:text-gray-200">
                  {customer.createdDate}
                </td>
                <td className="whitespace-nowrap py-4 pe-3 text-sm font-medium text-gray-900 dark:text-gray-200">
                  {customer.dueDate}
                </td>
                <td className="whitespace-nowrap py-4 px-3 text-center text-sm font-medium">
                  <a href="javascript:void(0);" className="me-0.5">
                    <i className="mgc_edit_line text-lg"></i>
                  </a>
                  <a href="javascript:void(0);" className="ms-0.5">
                    <i className="mgc_delete_line text-xl"></i>
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CandidatesTable;
