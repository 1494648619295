const Tag: React.FC<{ name: string }> = ({ name }) => {
  return (
    <a
      href="#"
      className="inline-flex items-center font-semibold py-1 px-2 rounded text-xs bg-primary/20 text-primary"
    >
      {name}
    </a>
  );
};

export default Tag;
