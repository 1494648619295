import FileCard from "./FileCard";

const AttachedFiles: React.FC = () => {
  return (
    <div className="mt-6">
      <h6 className="text-gray-800 font-medium mb-3">Attached Files</h6>
      <div className="grid md:grid-cols-4 gap-3">
        <FileCard name="Landing 1.psd" />
        <FileCard name="Landing 2.psd" />
      </div>
    </div>
  );
};

export default AttachedFiles;
