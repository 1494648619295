import React from "react";
import MenuItem from "./MenuItem";

interface SubMenuProps {
  iconClass: string;
  text: string;
  items: { to: string; text: string }[];
  isOpen: boolean;
  onClick: () => void;
}

const SubMenu: React.FC<SubMenuProps> = ({ iconClass, text, items, isOpen, onClick }) => {
  return (
    <li className="menu-item">
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault();
          onClick();
        }}
        data-fc-type="collapse"
        className={`menu-link fc-collapse ${isOpen ? "open" : ""}`}
      >
        <span className="menu-icon">
          <i className={iconClass}></i>
        </span>
        <span className="menu-text">{text}</span>
        <span className="menu-arrow"></span>
      </a>

      <ul className={`sub-menu ${!isOpen ? "hidden" : ""}`}>
        {items.map((item, index) => (
          <MenuItem key={index} to={item.to} text={item.text} />
        ))}
      </ul>
    </li>
  );
};

export default SubMenu;
