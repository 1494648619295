import React from "react";
import { useQuery } from "@apollo/client";
import { GET_GROUPS } from "../../graphql/queries/group";

interface GroupInputProps {
  group: string;
  setGroup: (group: string) => void;
}

const GroupInput: React.FC<GroupInputProps> = ({ group, setGroup }) => {
  // Fetch groups data using Apollo Client
  const { data, loading, error } = useQuery(GET_GROUPS);

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setGroup(e.target.value);
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading groups</p>;

  return (
    <div className="mb-3">
      <label
        htmlFor="groupSelect"
        className="text-gray-800 text-sm font-medium inline-block mb-2"
      >
        Select Group
      </label>
      <select
        id="groupSelect"
        className="form-select"
        value={group}
        onChange={handleChange}
        required
      >
        <option value="" disabled>
          Select a Group
        </option>
        {data.groups.map((group: any) => (
          <option key={group.id} value={group.id}>
            {group.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default GroupInput;
